// Use !important to override vendor inline styles
$lp-max-width: 274px;
$lp-max-width-product-card: 260px;
@mixin lpInlineCartButton {
  min-height: 0 !important;;
  display: inline-block !important;
  margin: auto !important;
  font-size: 12px !important;
  color: $color-light-black !important;
  text-align: #{$ldirection} !important;
  border: none !important;
  border-bottom: 1px solid $color-light-black !important;
  padding: 0 !important;
  padding-top: 5px !important;
  width: auto !important;
  margin-#{$ldirection}: 5px !important;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

@mixin lpBlockButton {
  display: block !important;
  background: transparent !important;
  color: $color-light-black !important;
  font-size: 15px !important;
  min-height: 63px !important;
  border: 1px solid $color-silver !important;
  margin: 10px auto 0 !important;
  width: 100%;
  text-align: center;
  border-radius: 0 !important;
}

.elc-lpc__prepended {
  background: $gray !important;
  padding: 20px !important;
}

.lp_desktop {
  .lp-window-root .lpc_window {
    #{$ldirection}: auto !important;
    #{$rdirection}: 0 !important;
  }
}

.lp_desktop,
.lp_mobile {
  .lp-window-root .lpc_window {
    .lp_minimize-icon.lp_icon-white img,
    .lp_close-icon.lp_icon-white img {
      top: 0 !important;
    }
    .lpc_card__button,
    .lp_cb_button,
    .lp_confirm_button,
    .lp_cancel_button,
    .lp_submit_button {
      @include lpBlockButton;
      width: 100% !important;
    }
    .lpc_card__image img {
      background: linear-gradient($color-gradient-setting-green) !important;
    }
    .lp_menu-top-icon.lp_icon-white svg path {
      fill: $color-light-black !important;
    }
    .lp_menu-top-btn.lpc_maximized-header__menu-button .lp_menu-top-icon {
      svg {
        margin-top: 4px !important;
        path {
          fill: $color-light-black !important;
        }
      }
    }

    .lpview_form_textarea {
      outline: none !important;
      color: $color-light-black !important;
      font-size: 15px;
      letter-spacing: .01em;
      &::-webkit-input-placeholder,
      &::placeholder {
        font-style: normal;
        color: $color-neutral-light-gray !important;
        font-size: 15px;
        letter-spacing: .01em;
      }
    }

    .lpc_maximized-header {
      background: $color-white !important;
      border: none !important;
      border-bottom: 1px solid $color-silver !important;
    }
    .lp_title {
      margin-#{$ldirection}: 5px !important;
    }
    .lp_logo_top_margin {
      margin-top: 0 !important;
    }
    .lp_time {
      color: $color-neutral-light-gray !important;
      font-size: 12px !important;
    }
    .lp_chat_line_wrapper {
      padding-#{$ldirection}: 15px !important;
      padding-#{$rdirection}: 15px !important;
    }
    .lp_chat_line  {
      background: transparent !important;
      border: none !important;
    }
    .lpc_message__text {
      font-family: $font--neue-haas-unica-pro !important;
      color: $color-light-black !important;
      font-size:  15px !important;
      line-height: get-line-height(15px, 23px) !important;
    }
    .lp_top-text {
      color: $color-darker-gray !important;
      font-size: 12px !important;
    }
    .lp_logo_area_wrapper,
    .lpc_message-tail,
    .lpc_message-tail__border,
    .agent_avatar,
    .lp_emoji_button,
    .lpc_message-area__indicator,
    .lp-json-pollock-layout-carousel-arrow {
      display: none !important;
    }

    .lp_rich_content_line {
      .lp-json-pollock > .lp-json-pollock-layout-vertical {
        border: none !important;
        max-width: 100% !important;
        .lp-json-pollock-layout-vertical,
        .lp-json-pollock-layout-horizontal {
          max-width: 100% !important;
        }
        .lp-json-pollock-element-text {
          font-size: 15px !important;
        }
        .lp-json-pollock-element-button {
          button {
            @include lpBlockButton;
          }
        }
      }
      .lpc_message__text_agent > .lp-json-pollock,
      .lp-json-pollock-layout-carousel-wrapper {
        display: block !important;
        max-width: $lp-max-width;
        padding: 0 !important;

        .lpc_card_vertical,
        .lpc_card_horizontal {
          border: none !important;
          max-width: 100% !important;
        }
      }
    }

    .lp-json-pollock-layout-carousel-wrapper {
      .lpc_card_vertical,
      .lpc_card_horizontal {
        .lp-json-pollock-element-text {
          padding: 5px !important;
        }
      }
    }

    .lpc_message_card {
      max-width: 100% !important;
      display: block !important;
      margin: 0 !important;
      padding: 15px 0 !important;
    }

    .chips-wrapper {
      margin: auto !important;
      .chips-row {
        display: block !important;
        height: auto !important;
      }
      button {
        @include lpBlockButton;
      }
    }
    .elc-content-card  {
      .lpc_card__text {
        padding-#{$ldirection}: 0 !important;
        padding-#{$rdirection}: 0 !important;
      }
      .lpc_card__button {
        border: none !important;
        button {
          @include lpBlockButton;
        }
      }
      &.BrnDej-1,
      &.qRnRDej-1 {
        .lp_rich_content_line {
          background: $color-primary-beige;
          max-width: $lp-max-width !important;
          padding-top: 0 !important;

          > .lpc_message__text > .lp-json-pollock > .lp-json-pollock-layout > .lp-json-pollock-layout {
            display: block !important;
            > * {
              display: block !important;
              width: 100% !important;
              background-color: $color-primary-beige !important;
            }
            .lp-json-pollock-element-text,
            .lp-json-pollock-element-button,
            .lp-json-pollock-element-button button {
              margin: 0 !important;
              padding: 0 15px !important;
            }

            .lp-json-pollock-element-button {
              padding-top: 18px !important;

              & ~ .lp-json-pollock-element-button {
                padding-top: 8px !important;
              }

              button {
                background-color: $white !important;
                border: 1px solid $color-silver !important;
                font-size: 15px !important;
                min-height: 47px !important;
              }
            }

            .lp-json-pollock-element-text {
              &:nth-child(1) {
                font-family: $font--lamer-headline !important;
                padding-top: 15px !important;
                line-height: 1.2 !important;
                font-size: 21px !important;
              }
              &:nth-child(2) {
                line-height: 1.5 !important;
                font-size: 12px !important;
              }
            }
          }
        }
      }

      // Product Card
      &.BrrFen-1 {
        max-width: $lp-max-width-product-card !important;
        .lpc_message_card {
          border-top: 1px solid $color-silver !important;
          border-bottom: 1px solid $color-silver !important;
        }
        .lpc_card_vertical,
        .lpc_card_horizontal {
          &[role="listitem"] {
            display: block !important;
            width: 100% !important;
            margin: auto !important;
          }
          .lpc_card_vertical {
            display: grid !important;
            padding: 15px !important;
            border-bottom: 1px solid $color-silver !important;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 0;
            grid-row-gap: 0;
            .lpc_card__image {
              grid-area: 1 / 1 / 2 / 1;
            }
            .lpc_card__text {
              grid-area: 1 / 2 / 2 / 2;
              padding-bottom: 0 !important;
            }
            .lpc_card__button {
              grid-area: 1 / 2 / 2 / 2;
            }
          }

          .lpc_card_vertical,
          .lpc_card_horizontal {
            .lp-json-pollock-element-text {
              font-size: 12px !important;
              color: $color-neutral-dark-gray;
              * {
                letter-spacing: 0.01em;
              }
            }
            .lpc_card__text {
              border: none !important;
            }
          }
          .lpc_card_horizontal {
            .lpc_card_vertical {
              display: block !important;
              width: 100% !important;
              padding: 0 0 48px !important;
              border: none !important;
            }
          }
        }

        .lpc_card__text {
          padding: 0 !important;
          &:last-child {
            padding-bottom: 2rem !important;
          }
        }
        .lpc_card__image {
          padding-#{$rdirection}: 20px;
        }
        .lp-json-pollock > .lp-json-pollock-layout > .lpc_card_horizontal:last-child {
          width: 50%;
          margin-#{$ldirection}: auto;
          text-align: #{$ldirection} !important;
          position: absolute;
          bottom: 0;
          #{$ldirection}: 50%;
          > .lpc_card__button {
            min-height: 0 !important;
            text-align: #{$ldirection};
            margin: 0 !important;
            button {
              @include lpInlineCartButton;
            }
          }
        }
        .elc-lp-card-product-shade-info {
          > * {
            width: 100% !important;
            &:first-child {
              display: none !important;
            }
          }
        }
        .lpc_card__text:last-child {
          padding-bottom: 0 !important;
        }
        .lpc_card__image {
          width: 120px !important;
          height: 122px !important;
          position: relative !important;
          &:before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            #{$ldirection}: 0;
            width: 100px;
            height: 100%;
            background: linear-gradient($color-gradient-setting-green) !important;
          }
          img {
            position: relative !important;
            z-index: 2 !important;
            background: none !important;
          }
        }
      }

      // Content Card
      &.qRnRDej-1 {
        .lp-json-pollock {
          max-width: 100% !important;
        }
        .lpc_card__button {
          display: block !important;
          width: 100% !important;
          button {
            display: block !important;
            width: 100% !important;
          }
        }
      }
    }
  }
}
