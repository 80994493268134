@import '../../../../scss/theme-bootstrap';
@import '_liveperson_theme';

// Ensure the close icon z-index is higher than the trigger z-index
$trigger-zindex: 2;
$close-icon-zindex: 3;

.liveperson-concierge {
  background: $color-primary-beige;
  position: fixed;
  z-index: 995;
  bottom: 0;
  #{$rdirection}: 0;
  color: $color-light-black;

  // Toggle mini and full proactive invite (full view)
  &[aria-expanded='false'] {
    .liveperson-concierge__wrapper--mini {
      display: flex;
    }
  }
  &[aria-expanded='true'] {
    .liveperson-concierge__wrapper--full {
      display: flex;
      flex-direction: column;
    }
  }

  // Level attribute selector
  // Control what content the cookie's level value displays
  &[level] [level] {
    display: none;
  }
  &[level='public'] [level='public'],
  &[level='0'] [level='0'],
  &[level='1'] [level='1'],
  &[level='2'] [level='2'],
  &[level='3'] [level='3'] {
    display: initial;
  }

  // Page attribute selector
  // Controls the targeted message to show for the detected page
  &[page] [page] {
    display: none;
  }
  &[page='default'] [page='default'],
  &[page='home'] [page='home'],
  &[page='mpp'] [page='mpp'],
  &[page='spp'] [page='spp'],
  &[page='custom'] [page='custom'] {
    display: initial;
  }

  &__wrapper {
    display: none;
    position: relative;

    &--mini {
      width: 64px;
      height: 64px;
      align-items: center;
      justify-content: center;
      @include breakpoint($large-up) {
        width: 104px;
        height: 104px;
      }
      svg {
        display: block;
        width: 24px;
      }
    }

    &--full {
      width: 100%;
      height: 175px;
      padding: 25px 20px;
      padding-#{$rdirection}: 80px;
      @include breakpoint($medium-up) {
        max-width: 390px;
        min-height: 219px;
        height: auto;
        padding: 40px;
        padding-#{$rdirection}: 80px;
      }
    }
  }

  &__trigger {
    position: absolute;
    z-index: $trigger-zindex;
    top: 0;
    #{$ldirection}: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    a {
      padding: 0 !important;
      background: transparent !important;
      -webkit-user-select: none;
    }
    > * {
      height: 100%;
      > * {
        height: 100%;
        opacity: 0;
      }
    }
    a,
    button {
      display: block;
      height: 100%;
      width: 100%;
      border: none;
    }
  }

  &__icon {
    position: absolute;
    z-index: $close-icon-zindex;
    top: 15px;
    #{$rdirection}: 20px;
    width: 16px;
    height: 32px;
    button {
      display: block;
      background: transparent;
      appearance: none;
      padding: 0;
      -webkit-appearance: none;
      border: none;
      width: 100%;
      height: 100%;
    }
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    @include typestyle--h7;
    text-transform: none;
    margin-bottom: 10px;

    &-agents {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-icon {
      display: block;
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
      margin-top: auto;
      margin-bottom: 5px;
    }
  }
}
